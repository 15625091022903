<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <b-link class="brand-logo" :to="{name:'auth-login'}">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">
          AgentScout
        </h2>
      </b-link>

      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
          v-if="areaFrom === true"
        >
          <b-card-title class="mb-1">
            Non ricordi i tuoi dati di login? 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            No problem!<br />Inserisci la tua email con sui sei registrato su Agentscout e ti invieremo un messaggio con un link per accedere direttamente al tuo account
          </b-card-text>

          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group
                label="Email"
                label-for="forgot-password-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="userEmail"
                    :disabled="disableButton"
                    :state="errors.length > 0 ? false:null"
                    name="forgot-password-email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="disableButton"
              >
                <div v-if="loading === true">
                  <b-spinner small class="mr-50" />
                  invio in corso
                </div>
                <div v-if="loading === false">
                  Invia
                </div>
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <feather-icon icon="ChevronLeftIcon" /> Torna al Login
            </b-link>
          </p>
        </b-col>

        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
          v-if="areaFrom === false"
        >
          <b-card-title class="mb-1">
            Ciao {{ nomeUtente }},
          </b-card-title>
          <b-card-text class="mb-2">
            controlla la tua email per accedere con il link di auto-login ricevuto
          </b-card-text>
          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <feather-icon icon="ChevronLeftIcon" /> Torna al Login
            </b-link>
          </p>
        </b-col>
      </b-col>
      
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BSpinner, BButton, BAlert,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BSpinner, 
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    BAlert, 
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      areaFrom: true,
      disableButton: false,
      loading: false,
      nomeUtente: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required,
      email,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {

          this.disableButton = true
          this.loading = true;

          this.$http.post('api/auth/password-recovery', {
            email: this.userEmail
            }).then(response => { 
                /*
                console.log("risposta server -------- status code -> "+response.data.statusCode)
                console.dir(response.data)
                */
                
                //rimuovi loading sul bottone
                this.disableButton = false
                this.loading = false;
                
                if(response.data.statusCode===200){
                //risposta positiva
                  /*
                  console.log("--------")
                  console.dir(response.data.reply)
                  console.log("--------")
                  console.log("nome -> "+response.data.reply.userData.name)
                  */
                  
                  this.areaFrom = false;                  
                  this.nomeUtente = response.data.reply.userData.name
                } else {
                  //risposta negativa (errore sul server)
                  this.$swal({
                    icon: 'error',
                    title: 'Si è verificato un errore',
                    text: response.data.reply.message,
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                  })

                  this.areaFrom = true;
                }

            }).catch(e => {
                console.log(e);
            });
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
